@use '@cloudscape-design/design-tokens/index' as awsui;

$viewport-breakpoint-s: 957px;
$open-navigation-width: 240px;

.custom-home__header {
  background-color: awsui.$color-background-home-header;
}

.custom-home__header-title {
  color: awsui.$color-text-home-header-default;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: awsui.$color-text-home-header-secondary;
}

@media only screen and (min-width: $viewport-breakpoint-s) {
  .custom-home__sidebar {
    // Aligns baseline of text with dark header bottom border
    margin-top: -86px;
  }
  .custom-home__header-cta {
    // Keeps 32px distance between sidebar cards
    margin-bottom: 38px;
  }
}

.custom-home__header-title > * {
  max-width: 700px;
}

.custom-home__header-cta > div {
    background-color: white !important;
    color: black !important;
}

h2 {
    color: black !important;
}